<script lang="ts">
	import { page } from "$app/stores";
	import Spinner from "src/components/common/spinner.svelte";
	import type { GetSectionsBasicQuery } from "src/graphql-operations";
	import { CountToolsDoneOfSectionByTeam } from "src/graphql-operations";
	import { findIconPath } from "src/helpers/find-icon-path";
	import iconFetcher from "src/lib/icon-fetcher";
	import userStore from "src/stores/user";
	import SectionProgressCircularBar from "./section-progress-circular-bar.svelte";

	const { project: projectId } = $page.params;

	export let section: GetSectionsBasicQuery["sections"][0],
		orientation: "ROW" | "COL" = "ROW";

	const toolsDone = CountToolsDoneOfSectionByTeam({
		variables: {
			section_id: section.id,
			team_id: projectId || userStore.getSelectedProjectId(),
		},
	});

	const colors: {
		[slug: (typeof section)["slug"]]: { bg: string; text: string };
	} = {
		think: {
			bg: "text-think-500",
			text: "text-think-700",
		},
		experience: {
			bg: "text-experience-500",
			text: "text-experience-500",
		},
		manage: {
			bg: "text-manage-500",
			text: "text-manage-500",
		},
	};
</script>

{#if orientation == "ROW"}
	<!-- content here -->
	<a href="/methodology?section={section.slug}">
		<div
			class="section-progress-card relative h-full w-full cursor-pointer rounded-[30px]
	       p-5 transition-all hover:-translate-y-1"
		>
			<div class="absolute left-5 top-5 flex flex-row items-center self-start">
				<img
					class="h-[62px] w-[62px]"
					alt={section.name}
					src={findIconPath({
						data: section.section_icons_files,
						type: "medium",
					})}
				/>
				<span class="text-2xl font-semibold {colors[section.slug].text}">
					{section.name}
				</span>
			</div>

			<div
				class="absolute bottom-5 left-5 self-end {colors[section.slug].text}"
			>
				{#if $toolsDone.loading}
					<Spinner color="{section.slug}-500" size="1.5rem" />
				{:else if $toolsDone.data}
					<p class="text-2xl font-semibold">
						{$toolsDone.data.done.aggregate.count} de {$toolsDone.data.all
							.aggregate.count}
					</p>
				{/if}
				<p class="text-sm font-semibold">Ferramentas Concluídas</p>
			</div>

			{#if !$toolsDone.loading}
				<div class="absolute bottom-5 right-5">
					<SectionProgressCircularBar
						allCount={$toolsDone.data.all.aggregate.count}
						colors={colors[section.slug]}
						doneCount={$toolsDone.data.done.aggregate.count}
					/>
				</div>
			{/if}
		</div>
	</a>
{:else}
	<div class="flex flex-col items-center justify-center space-y-5">
		<div>
			{#await iconFetcher.getContentIconPath("section", section.id, "medium") then iconPath}
				<img
					class="mx-auto h-[62px] w-[62px]"
					alt={section.name}
					src={iconPath}
				/>
			{/await}
			<span class="text-2xl font-semibold {colors[section.slug].text}">
				{section.name}
			</span>
		</div>
		{#if !$toolsDone.loading}
			<div class="w-full">
				<SectionProgressCircularBar
					allCount={$toolsDone.data.all.aggregate.count}
					colors={colors[section.slug]}
					doneCount={$toolsDone.data.done.aggregate.count}
				/>
			</div>
		{/if}
		<div class={colors[section.slug].text}>
			{#if $toolsDone.loading}
				<Spinner color="{section.slug}-500" size="1.5rem" />
			{:else if $toolsDone.data}
				<p class="text-center text-2xl font-semibold">
					{$toolsDone.data.done.aggregate.count} de {$toolsDone.data.all
						.aggregate.count}
				</p>
			{/if}
			<p class="text-xs font-bold">Ferramentas Concluídas</p>
		</div>
	</div>
{/if}

<style>
	.section-progress-card {
		background: linear-gradient(
			264.5deg,
			rgba(255, 255, 255, 0.5) 6.2%,
			rgba(255, 255, 255, 0.265) 100.99%
		);
		box-shadow:
			5px 5px 18px rgba(0, 0, 0, 0.1),
			inset 2px 6px 27px rgba(0, 0, 0, 0.1);
	}
</style>
